@import "./../../../../styles/colors.css";

.user-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 101px;
  right: 15px;
  width: fit-content;
  height: fit-content;
  background-color: var(--light-blue);
  border-radius: 20px 0 20px 20px;
  border: 1px solid var(--blue);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 5;
}

.user-menu-link {
  border-radius: 18px;
  padding: 8px 16px;
  cursor: pointer;
}

.user-menu-link span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  color: var(--white);
}

.user-menu-link a {
  width: fit-content;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  text-decoration: none;
  color: var(--white);
  font-size: 16px;
  font-weight: 400;
}

.user-menu-link:hover {
  background-color: var(--blue);
}

.user-menu-link.active {
  background-color: var(--blue);
  cursor: unset;
}
