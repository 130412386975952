@import "./../../../../styles/colors.css";

.large-btn {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin: 12px;
  width: fit-content;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  background-color: var(--blue);
  border: 1px solid var(--blue);
  transition: 300ms ease-in-out;
  cursor: pointer;
}

.large-btn p {
  color: var(--white);
  font-size: 23px;
  font-weight: 400;
}

.large-btn:not(:disabled):hover {
  background-color: var(--white);
  border: 1px solid var(--blue);
}

.large-btn:not(:disabled):hover p {
  transition: 300ms ease-in-out;
  color: var(--blue);
}

.large-btn:not(:disabled):hover .large-btn-icon {
  transition: 300ms ease-in-out;
  filter: brightness(0) saturate(100%) invert(26%) sepia(77%) saturate(2342%)
    hue-rotate(228deg) brightness(83%) contrast(115%);
}

.large-btn:disabled {
  background-color: var(--light-gray);
  color: rgba(255, 255, 255, 0.9);
  border: 1px solid var(--gray);
  color: var(--black);
  cursor: default;
}

.large-btn:disabled p {
  color: var(--dark-gray);
}

.large-btn:disabled .large-btn-icon {
  filter: brightness(0) saturate(100%) invert(45%) sepia(0%) saturate(1279%)
    hue-rotate(222deg) brightness(94%) contrast(89%);
}

.large-btn-icon {
  height: 24px;
  width: auto;
  margin: 0 4px;
}

/* White button */

.large-btn.white {
  background-color: var(--white);
  border: 1px solid var(--blue);
}

.large-btn.white p {
  color: var(--blue);
}

.large-btn-icon.white {
  filter: brightness(0) saturate(100%) invert(26%) sepia(77%) saturate(2342%)
    hue-rotate(228deg) brightness(83%) contrast(115%);
}

.large-btn.white:not(:disabled):hover {
  background-color: var(--blue);
}

.large-btn.white:not(:disabled):hover p {
  transition: 300ms ease-in-out;
  color: var(--white);
}

.large-btn.white:not(:disabled):hover .large-btn-icon {
  transition: 300ms ease-in-out;
  filter: none;
}

.large-btn.white:disabled {
  background-color: var(--light-gray);
  color: rgba(255, 255, 255, 0.9);
  border: 1px solid var(--gray);
  color: var(--black);
  cursor: default;
}

.large-btn.white:disabled p {
  color: var(--dark-gray);
}

/* Media queries */
@media screen and (max-width: 650px) {
  .large-btn p {
    font-size: 20px;
  }
}

@media screen and (max-width: 450px) {
  .large-btn {
    padding: 8px 12px;
    margin: 6px;
  }

  .large-btn p {
    font-size: 18px;
  }
}
