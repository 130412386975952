@import "./../../../styles/colors.css";

/* Header Styles */
.contact-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-details-headline {
  display: flex;
  align-items: center;
  position: relative;
  height: 73px;
}

.contact-details-title {
  font-size: 61px;
  font-weight: 700;
  padding-right: 24px;
}

.contact-details-metrics-txt {
  font-size: 27px;
  font-weight: 400;
  padding-left: 24px;
}

.contact-details-blue-bar {
  height: 80%;
  width: 3px;
  background-color: var(--light-blue);
}

/* Content Styles */
.contact-details-content {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  max-width: 100%;
}

.contact-details-user-content {
  display: flex;
  align-items: center;
}

.contact-details-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 120px;
  min-width: 120px;
  height: 120px;
  border-radius: 100%;
  border: 4px solid var(--white);
}
.contact-details-circle img {
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: 0;
  right: 0;
}

.contact-details-initials {
  font-size: 47px;
  font-weight: 500;
  color: var(--white);
  text-shadow: 1px 1px 2px var(--black);
}

.contact-details-name {
  font-size: 47px;
  font-weight: 600;
  padding-left: 32px;
}

.word-wrap {
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

/* Buttons */
.contact-details-return-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  cursor: pointer;
}
.contact-details-return-button img {
  width: 22px;
  height: 22px;
  filter: brightness(0) saturate(100%) invert(56%) sepia(64%) saturate(570%)
    hue-rotate(154deg) brightness(92%) contrast(92%);
}
.contact-details-return-button:hover {
  background-color: var(--light-blue);
  border-radius: 100%;
  scale: 1.1;
}
.contact-details-return-button:hover img {
  scale: 1.1;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7485%)
    hue-rotate(325deg) brightness(100%) contrast(104%);
}

.contact-details-edit-options {
  display: flex;
  align-items: center;
  padding: 12px 0 0 32px;
}
.contact-details-edit-options img {
  width: 20px;
  height: 20px;
  padding-right: 12px;
  filter: brightness(0) saturate(100%) invert(47%) sepia(49%) saturate(3484%)
    hue-rotate(203deg) brightness(103%) contrast(103%);
}
.contact-details-edit-options p {
  font-size: 16px;
  font-weight: 400;
  padding-right: 24px;
}

.contact-details-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.contact-details-button:hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(87%) saturate(4028%)
    hue-rotate(215deg) brightness(100%) contrast(108%);
}

/* Contact Styles */
.contact-details-contact {
  margin-top: 48px;
}

.contact-details-contact-title {
  font-size: 24px;
  font-weight: 600;
}

.contact-details-info {
  margin-top: 16px;
}
.contact-details-info p {
  font-size: 16px;
  font-weight: 700;
  padding: 24px 0 12px 0;
}
.contact-details-info span {
  font-size: 16px;
  font-weight: 400;
}
.contact-details-info a {
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  color: var(--light-blue);
  cursor: pointer;
}
.contact-details-info a:hover {
  text-decoration: underline;
}

/* Mobile Button */
.contact-details-button-mobile {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 100px;
  right: 20px;
  height: 56px;
  width: 56px;
  border-radius: 25%;
  border: 0;
  background-color: var(--light-blue);
  z-index: 1;
  cursor: pointer;
}
.contact-details-button-mobile img {
  padding: 0;
  width: 7px;
  height: 32px;
}
.contact-details-button-mobile:hover {
  background-color: var(--blue);
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.3);
}

/* Animation */
.contact-details-animation-coming-in {
  animation: coming-in 250ms ease-in-out;
  opacity: 1;
}

@keyframes coming-in {
  0% {
    opacity: 0;
    transform: translateX(100vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive Styles */
@media screen and (max-width: 1300px) {
  .contact-details-title {
    font-size: 50px;
  }

  .contact-details-metrics-txt {
    font-size: 24px;
  }
}

@media screen and (max-width: 1235px) {
  .contact-details-headline {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
    height: 100%;
  }

  .contact-details-metrics-txt {
    padding-left: 0;
  }

  .contact-details-blue-bar {
    position: absolute;
    bottom: -15px;
    height: 3px;
    width: 100px;
  }

  .contact-details-name {
    font-size: 32px;
  }
}

@media screen and (max-width: 450px) {
  .contact-details-edit-options {
    display: none;
  }

  .contact-details-button-mobile {
    display: flex;
  }

  .contact-details-name {
    font-size: 36px;
    font-weight: 600;
    padding-left: 18px;
  }

  .contact-details-circle {
    width: 80px;
    min-width: 80px;
    height: 80px;
    border: 2px solid var(--white);
  }

  .contact-details-initials {
    font-size: 27px;
    font-weight: 400;
  }

  .contact-details-title {
    font-size: 47px;
    font-weight: 700;
  }

  .contact-details-metrics-txt {
    font-size: 20px;
    font-weight: 400;
  }
}
