.small-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.small-btn:hover {
  scale: 1.1;
}
