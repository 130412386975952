@import "./../../styles/colors.css";

.register {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 482px;
  height: fit-content;
}

/* Header */

.register-header {
  width: 100%;
}

.register-headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.register-spacer {
  padding-left: 24px;
}

.register-title {
  font-size: 61px;
  font-weight: 700;
  text-align: center;
}

.register-line {
  display: flex;
  justify-content: center;
  margin: 12px 0 32px 0;
  width: 100%;
}

/* Input fields */

.register-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 80%;
}

.input-fields input {
  width: 100%;
  height: 40px;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid var(--medium-gray);
  padding: 0 12px;
  outline: none;
  background: transparent;
}

.input-fields input::placeholder {
  color: var(--medium-gray);
}

.input-fields input:focus {
  border-bottom: 1px solid var(--light-blue);
}

.register-button {
  display: flex;
  justify-content: center;
}

/* Icons */

.register-icon-user {
  width: 24px;
  height: auto;
  position: absolute;
  top: 8px;
  right: 0;
}

.register-icon-mail {
  width: 24px;
  height: auto;
  position: absolute;
  top: 80px;
  right: 0;
}

.register-icon-password {
  width: 20px;
  height: auto;
  position: absolute;
  top: 145px;
  right: 2px;
}

.register-icon-password-confirm {
  width: 20px;
  height: auto;
  position: absolute;
  top: 217px;
  right: 2px;
}

.register-icon-eye {
  width: 28px;
  height: auto;
  position: absolute;
  top: 145px;
  right: -2px;
  cursor: pointer;
}

.register-icon-eye-confirm {
  width: 28px;
  height: auto;
  position: absolute;
  top: 217px;
  right: -2px;
  cursor: pointer;
}

.register-icon-eye:hover,
.register-icon-eye-confirm:hover {
  filter: brightness(0) saturate(100%) invert(46%) sepia(51%) saturate(2924%)
    hue-rotate(202deg) brightness(102%) contrast(101%);
}

/* Checkbox */

.register-privacy-policy {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.register-privacy-policy input[type="checkbox"] {
  width: 24px;
  height: 24px;
  margin: 0 10px 0 15px;
  appearance: none;
  border: 0;
  border-radius: 0;
  background-image: url("./../../../public/assets/img/auth/checkbox.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.register-privacy-policy input[type="checkbox"]:checked {
  background-image: url("./../../../public/assets/img/auth/checkbox-checked.svg");
}

.register-privacy-policy input[type="checkbox"]:checked:hover {
  background-image: url("./../../../public/assets/img/auth/checkbox-checked-hover.svg");
}

.register-privacy-policy input[type="checkbox"]:hover {
  background-image: url("./../../../public/assets/img/auth/checkbox-hover.svg");
}

.register-privacy-policy label p {
  font-size: 14px;
  font-weight: 400;
  padding-right: 20px;
}

.register-privacy-policy label a {
  text-decoration: none;
  color: var(--light-blue);
  cursor: pointer;
}

.register-privacy-policy label a:hover {
  text-decoration: underline;
}

/* Error message */

.register-error-msg {
  text-align: center;
  height: 24px;
  padding: 6px;
  width: 100%;
}

.register-error-msg p {
  font-size: 12px;
  font-weight: 400;
  color: var(--red);
}

/* Media queries */
@media screen and (max-width: 650px) {
  .register,
  .register-form {
    width: 100%;
  }

  .register-line img {
    width: 120px;
  }

  .register-input-fields {
    width: 90%;
  }

  .register-title {
    font-size: 47px;
  }
}

@media screen and (max-width: 450px) {
  .register-line img {
    width: 100px;
  }

  .register-error-msg {
    height: 21px;
  }
  .register-error-msg p {
    font-size: 11px;
  }

  .register-title {
    font-size: 36px;
  }
}

@media screen and (max-width: 360px) {
  .register-line img {
    width: 80px;
  }
}
