@import "./../../styles/colors.css";

main {
  width: 100%;
  max-height: 100vh;
  overflow: auto;
}

/* Loader */

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  z-index: 999;
  flex-direction: column;
}

.loader {
  border: 16px solid var(--white);
  border-top: 16px solid var(--light-blue);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.loading-text {
  margin-top: 20px;
  color: var(--white);
  text-shadow: 3px 3px 3px var(--black);
  font-size: 21px;
  font-weight: 400;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {
  main {
    margin-bottom: 76px;
  }
}
