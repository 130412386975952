@import "./../../../styles/colors.css";

/* Task details */
.task-details {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.task-details-dialog {
  width: 525px;
  padding: 48px 40px;
  margin: 0 12px;
  border-radius: 30px;
  background-color: var(--white);
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.task-details-content {
  height: calc(700px - 114px);
  padding-right: 12px;
  overflow-y: auto;
}

.task-details-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.task-details-category {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23px;
  font-weight: 400;
  padding: 4px 16px;
  border-radius: 8px;
  color: var(--white);
  text-shadow: 1px 1px 2px var(--black);
}

.task-details-headline {
  font-size: 32px;
  font-weight: 700;
  width: 100%;
  word-wrap: break-word;
}

.task-details-description {
  font-size: 20px;
  font-weight: 400;
  margin-top: 32px;
  width: 100%;
  word-wrap: break-word;
}

/* Date */

.task-details-date {
  display: flex;
  margin-top: 32px;
  font-size: 19px;
  font-weight: 400;
  width: 100%;
}

.task-details-date p {
  color: var(--very-dark-gray);
  font-size: 20px;
  font-weight: 400;
  width: 120px;
  margin: 0;
}

/* Priority */

.task-details-priority {
  display: flex;
  align-items: center;
  margin-top: 32px;
  font-size: 19px;
  font-weight: 400;
  width: 100%;
}

.task-details-priority p {
  color: var(--very-dark-gray);
  font-size: 20px;
  font-weight: 400;
  width: 120px;
  margin: 0;
}

.task-details-priority-bg {
  margin-left: 12px;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  height: 32px;
}

.task-details-priority-urgent {
  background-image: url(./../../../../public/assets/img/urgent.svg);
}

.task-details-priority-medium {
  background-image: url(./../../../../public/assets/img/medium.svg);
}

.task-details-priority-low {
  background-image: url(./../../../../public/assets/img/low.svg);
}

/* Assigned & Creator */

.task-details-assigned,
.task-details-creator {
  margin-top: 32px;
  overflow-y: auto;
}

.task-details-assigned p,
.task-details-creator p {
  color: var(--very-dark-gray);
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 12px;
  margin: 0;
}

.task-details-users {
  display: flex;
  align-items: center;
  padding: 12px;
}

.task-details-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  min-width: 42px;
  height: 42px;
  border-radius: 100%;
  border: 2px solid var(--white);
}

.task-details-initials {
  font-size: 12px;
  font-weight: 400;
  color: var(--white);
  text-shadow: 1px 1px 2px var(--black);
}

.task-details-details {
  margin-left: 24px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.task-details-name {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}

.task-details-name p {
  color: var(--black);
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}

.task-details-last-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Btns */

.task-details-btns {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  margin-top: 12px;
}

.task-details-btns img {
  width: 20px;
  height: 20px;
  padding-right: 12px;
  filter: brightness(0) saturate(100%) invert(47%) sepia(49%) saturate(3484%)
    hue-rotate(203deg) brightness(103%) contrast(103%);
}

.task-details-btns p {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.task-details-btns span {
  padding: 0 12px;
}

.task-details-btns .task-details-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.task-details-btns .task-details-btn:hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(87%) saturate(4028%)
    hue-rotate(215deg) brightness(100%) contrast(108%);
}

.task-details-btns .task-details-btn:last-child {
  padding-right: 24px;
}

/* Subtasks */

.task-details-subtask {
  margin-top: 32px;
  overflow-y: auto;
}

.task-details-subtask p {
  color: var(--very-dark-gray);
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 12px;
  margin: 0;
}

.task-details-subtask-list {
  margin-top: 12px;
}

.task-details-single-subtask {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: 14px;
  padding: 6px 12px;
  cursor: pointer;
}
.task-details-single-subtask p {
  color: var(--black);
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 0 18px;
  padding-right: 12px;
}
.task-details-single-subtask img {
  width: 24px;
  height: 24px;
}
.task-details-single-subtask:hover {
  border-radius: 12px;
  background-color: var(--light-gray);
}
.task-details-single-subtask img:hover {
  filter: invert(60%) sepia(58%) saturate(1629%) hue-rotate(165deg)
    brightness(92%) contrast(92%);
}

/* Notice */

.task-details-notice p {
  font-size: 16px;
  font-weight: 400;
  margin: 12px 0 0 0;
  text-align: center;
}

/* Media Queries */

@media screen and (max-height: 800px) {
  .task-details-content {
    height: calc(580px - 114px);
    padding-right: 12px;
    overflow-y: auto;
  }
}

@media screen and (max-width: 650px) {
  .task-details-dialog {
    padding: 24px 20px;
  }

  .task-details-headline {
    font-size: 26px;
  }

  .task-details-description,
  .task-details-date,
  .task-details-priority,
  .task-details-assigned,
  .task-details-subtasks {
    font-size: 16px;
  }

  .task-details-subtasks p,
  .task-details-assigned p,
  .task-details-date p,
  .task-details-priority p,
  .task-details-creator p,
  .task-details-subtask p {
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {
  .task-details-notice p {
    font-size: 14px;
  }

  .task-details-priority p,
  .task-details-date p {
    width: 90px;
  }
}
