@import "./../../../styles/colors.css";

.contacts {
  display: flex;
  height: 100%;
}

.contacts-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 56px;
  width: 300px;
  padding: 8px 8px 8px 16px;
  margin-left: 45px;
  border-radius: 10px;
  border: 0;
  background-color: var(--light-blue);
  color: var(--white);
  cursor: pointer;
  font-size: 21px;
  font-weight: 700;
}

.contacts-btn-inside {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contacts-btn:hover {
  background-color: var(--blue);
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.3);
}
.contacts-btn img {
  padding-left: 12px;
}

.contacts-btn-inside-mobile {
  display: none;
}

.contacts-contact-list {
  width: 400px;
  min-width: 400px;
  padding-top: 32px;
  box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.contacts-contact-detail {
  padding: 32px;
  width: 100%;
  overflow: auto;
}

.contacts-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 24px;
  width: calc(100% - 48px);
}

.contacts-first-letter {
  font-size: 20px;
  font-weight: 500;
  padding-left: 32px;
  margin: 32px 0 12px 0;
}

.contacts-line {
  width: 100%;
  height: 1px;
  margin-bottom: 6px;
  background-color: var(--light-gray);
}

.contacts-contact {
  display: flex;
  align-items: center;
  width: calc(100% - 32px);
  padding: 16px;
  border-radius: 10px;
  cursor: pointer;
}
.contacts-contact:hover {
  background-color: var(--very-light-blue);
}
.contacts-contact-active {
  color: var(--white);
  background-color: var(--light-blue);
  cursor: unset;
}
.contacts-contact-active .contacts-email {
  color: var(--white);
}
.contacts-contact-active:hover {
  background-color: var(--light-blue);
}

.contacts-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 42px;
  min-width: 42px;
  height: 42px;
  border-radius: 100%;
  border: 2px solid var(--white);
}
.contacts-circle .contacts-initials {
  font-size: 14px;
  font-weight: 400;
  color: var(--white);
  text-shadow: 1px 1px 2px var(--black);
}
.contacts-circle img {
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: 0;
  right: 0;
}

.contacts-name {
  margin-left: 24px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.contacts-first-name {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 6px;
}

.contacts-first-name p {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}

.contacts-last-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.contacts-email {
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  color: var(--light-blue);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.d-none {
  display: none;
}

.max-width {
  width: 100% !important;
}

/* Mobile Nav */

.contacts-nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 146px;
  right: 80px;
  width: 110px;
  height: 108px;
  background-color: var(--light-blue);
  border-radius: 20px 20px 0 20px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 5;
}

.contacts-nav-link {
  display: flex;
  align-items: center;
  height: 46px;
  padding: 8px 16px;
  border-radius: 8px;
  color: var(--white);
  cursor: pointer;
}
.contacts-nav-link span {
  text-align: center;
  width: 118px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
.contacts-nav-link:hover {
  background-color: var(--blue);
}

/*------------- RESPONSIVE -------------*/
@media screen and (max-width: 1050px) {
  .contacts-contact-list {
    right: 0;
    width: auto;
    background-color: var(--bgContent);
    z-index: 1;
  }

  .contacts-contact-detail {
    top: 96px;
    left: 232px;
    bottom: 0;
    right: 0;
  }

  .contacts-contact:hover {
    background-color: var(--light-gray);
  }
}

@media screen and (max-width: 600px) {
  .contacts-contact-list {
    top: 80px;
    left: 0;
    bottom: 80px;
    padding: 0;
  }

  .contacts-contact-detail {
    top: 80px;
    left: 0;
    bottom: 80px;
  }

  .contacts-btn-inside {
    display: none;
  }

  .contacts-btn-inside-mobile {
    display: block;
  }

  .contacts-btn {
    position: fixed;
    bottom: 100px;
    right: 24px;
    justify-content: center;
    height: 56px;
    width: 56px;
    border-radius: 25%;
    z-index: 1;
    padding: 0;
    cursor: pointer;
  }

  .contacts-btn img {
    padding: 0;
    width: 32px;
    height: 32px;
  }
}

@media screen and (max-width: 450px) {
  .contacts-content {
    padding: 16px;
    width: calc(100% - 32px);
  }

  .contacts-contact-list {
    min-width: 0;
  }

  .contacts-contact-detail {
    padding: 16px;
  }

  .contacts-first-letter:first-child {
    margin-top: 0;
  }

  .contacts-contact {
    width: calc(100% - 16px);
    padding: 16px 8px;
  }

  .contacts-first-letter {
    padding-left: 16px;
  }
}
