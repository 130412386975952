@import "./../../styles/colors.css";

.forgot-pw {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 652px;
}

.forgot-pw-notice {
  text-align: center;
  padding: 12px;
}
.forgot-pw-notice p {
  color: var(--gray);
  font-size: 21px;
  font-weight: 400;
}

.forgot-pw-login {
  text-align: center;
  padding-top: 24px;
}
.forgot-pw-login a {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  cursor: pointer;
  width: fit-content;
  text-decoration: none;
  cursor: pointer;
}
.forgot-pw-login a:hover {
  color: var(--light-blue);
  font-weight: 600;
}

/* Header */

.forgot-pw-header {
  width: 100%;
}

.forgot-pw-headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.forgot-pw-spacer {
  padding-left: 24px;
}

.forgot-pw-title {
  font-size: 61px;
  font-weight: 700;
  text-align: center;
}

.forgot-pw-line {
  display: flex;
  justify-content: center;
  margin: 12px 0 32px 0;
  width: 100%;
}

.forgot-pw-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Input fields */

.forgot-pw-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.forgot-pw-input-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
}

.forgot-pw-input-fields input {
  width: 100%;
  height: 40px;
  margin-bottom: 12px;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid var(--medium-gray);
  padding: 0 12px;
  outline: none;
  background: transparent;
}

.forgot-pw-input-fields input::placeholder {
  color: var(--medium-gray);
}

.forgot-pw-input-fields input:focus {
  border-bottom: 1px solid var(--light-blue);
}

/* Buttons */

.forgot-pw-button {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  gap: 24px;
}

/* Icons */

.forgot-pw-icon-mail {
  width: 24px;
  height: auto;
  position: absolute;
  top: 8px;
  right: 0;
}

/* Media queries */
@media screen and (max-width: 820px) {
  .forgot-pw {
    width: 482px;
  }
}

@media screen and (max-width: 650px) {
  .forgot-pw,
  .forgot-pw-form {
    width: 100%;
  }

  .forgot-pw-line img {
    width: 120px;
  }

  .forgot-pw-input-fields {
    width: 90%;
  }

  .forgot-pw-title {
    font-size: 47px;
  }
}

@media screen and (max-width: 450px) {
  .forgot-pw-line img {
    width: 100px;
  }

  .forgot-pw-error-msg {
    height: 21px;
  }
  .forgot-pw-error-msg p {
    font-size: 11px;
  }

  .forgot-pw-title {
    font-size: 36px;
  }

  .forgot-pw-notice p {
    font-size: 19px;
  }
}

@media screen and (max-width: 360px) {
  .forgot-pw-line img {
    width: 80px;
  }
}
