@import "./../../../styles/colors.css";

/* Assigned */
.assigned {
  position: relative;
}

.assigned-list {
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  width: calc(100% - 24px);
  height: auto;
  max-height: 125px;
  padding: 12px;
  border-radius: 0px 0px 20px 20px;
  border: 1px solid var(--light-gray);
  border-top: 0px;
  background-color: var(--white);
  overflow-y: auto;
}

.assigned-content {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 6px;
  cursor: pointer;
}
.assigned-content:hover {
  background-color: var(--light-gray);
}

.assigned-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  min-width: 35px;
  height: 35px;
  border-radius: 100%;
  border: 2px solid var(--white);
}

.assigned-circle .assigned-initials {
  font-size: 12px;
  font-weight: 400;
  color: var(--white);
  text-shadow: 1px 1px 2px var(--black);
}

.assigned-details {
  margin-left: 24px;
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.assigned-first-name {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 400;
}

.assigned-last-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.assigned-checkbox img {
  width: 18px;
  height: 18px;
}

.assigned-badge {
  display: flex;
  align-items: center;
  margin: 9px;
  height: 40px;
  gap: 6px;
}

.assigned-dialog {
  position: fixed;
  width: min-content;
  height: fit-content;
  padding: 10px 15px;
  border-radius: 0px 20px 20px 20px;
  border: 1px solid var(--black);
  z-index: 2;
  background-color: var(--white);
}

.assigned-dialog p {
  font-size: 18px;
  font-weight: 400;
}

.assigned-selected {
  background-color: var(--light-blue);
  color: var(--white);
}

.assigned-selected img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%)
    hue-rotate(346deg) brightness(99%) contrast(103%);
}

.assigned-selected:hover {
  background-color: var(--blue);
}
