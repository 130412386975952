:root {
  --black: #000;
  --white: #fff;
  --gray: #b3b3b3;
  --dark-gray: #6e6e6e;
  --very-dark-gray: #42526e;
  --medium-gray: #d1d1d1;
  --light-gray: #e5e5e5;
  --light-light-gray: #d1d1d1;
  --very-light-gray: #f6f7f8;
  --blue: #015dff;
  --medium-blue: #4589ff;
  --light-blue: #4589ff;
  --very-light-blue: #d2e3ff;
  --red: #ff3d00;
  --light-red: #ff8190;
}
