@import "./../../../styles/colors.css";

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.dialog {
  width: fit-content;
  height: fit-content;
  text-align: center;
  padding: 18px 36px;
  border-radius: 24px;
  background-color: var(--light-blue);
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.dialog p {
  font-size: 21px;
  font-weight: 500;
  color: var(--white);
  margin: 0;
}
