@import "./../../../../styles/colors.css";

.privacy-policy {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  padding: 24px;
}

.privacy-policy-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.privacy-policy h2 {
  font-size: 61px;
  font-weight: 700;
  width: 85%;
  margin: 0;
}

.privacy-policy h3 {
  font-size: 27px;
  font-weight: 700;
  padding: 48px 0 24px 0;
  margin: 0;
}

.privacy-policy h4 {
  font-size: 20px;
  font-weight: 700;
  padding: 24px 0;
  margin: 0;
}

.privacy-policy p,
.privacy-policy li {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.03em;
  padding-bottom: 10px;
}

.privacy-policy span {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.03em;
}

.privacy-policy a {
  color: var(--light-blue);
  text-decoration: none;
}
.privacy-policy a:hover {
  text-decoration: underline;
}

/* Media queries */

@media screen and (max-width: 500px) {
  .privacy-policy h2 {
    font-size: 47px;
  }

  .privacy-policy h3 {
    font-size: 27px;
  }
}
