@import "./../../../styles/colors.css";

/* Subtask */
.subtask {
  position: relative;
}
.subtask-add {
  position: absolute;
  top: 48px;
  right: 15px;
  width: 16px;
  height: 16px;
}
.subtask-btns {
  display: flex;
  align-items: center;
  position: absolute;
  top: 48px;
  right: 15px;
}
.subtask-btns img {
  width: 16px;
  height: 16px;
  filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7500%)
    hue-rotate(327deg) brightness(96%) contrast(104%);
  cursor: pointer;
}
.subtask-btns img:last-child {
  width: 23px;
  height: 23px;
}
.subtask-btns img:hover {
  filter: invert(60%) sepia(58%) saturate(1629%) hue-rotate(165deg)
    brightness(92%) contrast(92%);
}
.subtask-line {
  height: 18px;
  padding: 1px;
  margin: 0 9px;
  background-color: var(--light-gray);
}
.subtask p {
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 6px;
  margin: 0;
}

/* Subtask list */
.subtask-list {
  max-height: 80px;
  overflow-x: auto;
  margin-top: 12px;
}
.single-subtask {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 9px;
  cursor: pointer;
}
.single-subtask p {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}
.single-subtask:hover {
  border-radius: 12px;
  background-color: var(--light-gray);
}
.single-subtask img {
  width: 16px;
  height: 16px;
}
.single-subtask img:hover {
  filter: invert(60%) sepia(58%) saturate(1629%) hue-rotate(165deg)
    brightness(92%) contrast(92%);
}
