@import "./../../styles/colors.css";

.login {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 482px;
  height: fit-content;
}

.login-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.login-title {
  font-size: 61px;
  font-weight: 700;
  text-align: center;
}

.login-line {
  display: flex;
  justify-content: center;
  margin: 12px 0 32px 0;
  width: 100%;
}

.login-input-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
}

.login-input-fields input {
  width: 100%;
  height: 40px;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid var(--medium-gray);
  padding: 0 12px;
  outline: none;
  background: transparent;
}

.login-input-fields input::placeholder {
  color: var(--medium-gray);
}

.login-input-fields input:focus {
  border-bottom: 1px solid var(--light-blue);
}

.login-buttons {
  display: flex;
  justify-content: center;
}

.login-forgot-pw {
  text-align: center;
  padding-bottom: 24px;
}
.login-forgot-pw a {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: var(--gray);
  cursor: pointer;
  width: fit-content;
  text-decoration: none;
  cursor: pointer;
}
.login-forgot-pw a:hover {
  color: var(--light-blue);
  font-weight: 600;
}

/* Icons */

.login-icon-mail {
  width: 24px;
  height: auto;
  position: absolute;
  top: 8px;
  right: 0;
}

.login-icon-password {
  width: 20px;
  height: auto;
  position: absolute;
  top: 80px;
  right: 2px;
}

.login-icon-eye {
  width: 28px;
  height: auto;
  position: absolute;
  top: 80px;
  right: -2px;
  cursor: pointer;
}

.login-icon-eye:hover {
  filter: brightness(0) saturate(100%) invert(46%) sepia(51%) saturate(2924%)
    hue-rotate(202deg) brightness(102%) contrast(101%);
}

/* Error message */
.login-error-msg {
  text-align: center;
  height: 24px;
  padding: 6px;
  width: 110%;
}

.login-error-msg p {
  font-size: 12px;
  font-weight: 400;
  color: var(--red);
}

/* Media queries */
@media screen and (max-width: 650px) {
  .login,
  .login-form {
    width: 100%;
  }

  .login-line img {
    width: 120px;
  }

  .login-input-fields {
    width: 90%;
  }

  .login-title {
    font-size: 47px;
  }
}

@media screen and (max-width: 450px) {
  .login-line img {
    width: 100px;
  }

  .login-error-msg {
    height: 21px;
  }
  .login-error-msg p {
    font-size: 11px;
  }

  .login-title {
    font-size: 36px;
  }
}

@media screen and (max-width: 360px) {
  .login-line img {
    width: 80px;
  }
}
