@import "./../../styles/colors.css";

/* Responsive */

@media screen and (max-width: 1200px) {
  .summary {
    padding: 48px 0;
  }

  /* Content */

  .summary-content-container-upper {
    flex-direction: column;
    align-items: center;
  }

  .summary-content-container-lower {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
  }

  .summary-content-container-lower .task-in-board,
  .summary-content-container-lower .task-other,
  .summary-content-container-lower .task-todo,
  .summary-content-container-lower .task-done {
    width: 100%;
    height: 200px;
    box-sizing: border-box;
  }

  /* Tasks */

  .task-todo {
    width: 591px;
    height: 60px;
    padding: 48px;
  }

  .task-todo-info {
    flex-direction: row;
  }

  .task-todo-info p {
    padding-left: 24px;
  }

  .task-todo:hover {
    border-color: rgba(0, 0, 0, 0.05);
    border-top-left-radius: 0px;
    background-color: var(--white);
    transform: scale(1.02);
  }

  .task-in-board:hover,
  .task-other:hover,
  .task-done:hover {
    border-color: var(--blue);
    border-top-left-radius: 0px;
    background-color: var(--blue);
    transform: scale(1.02);
  }

  .urgent-task:hover .urgent-task-container,
  .task-todo:hover .task-todo-container,
  .task-other:hover .task-other-container,
  .task-done:hover .task-done-container,
  .task-in-board:hover .task-in-board-container {
    transform: scale(1.2);
  }
}

@media screen and (max-width: 800px) {
  .summary-content-container-upper,
  .summary-content-container-lower {
    width: 500px;
  }

  .summary-headline {
    display: none;
  }

  .urgent-task,
  .task-todo {
    width: 450px;
    padding: 14px 24px;
  }

  .task-in-board,
  .task-other,
  .task-done {
    height: 134px !important;
    padding: 14px 24px;
  }

  .urgent-task-info {
    width: 120px;
    padding-left: 12px;
  }

  .urgent-task-divider {
    height: 85%;
  }

  .urgent-task-container {
    gap: 18px;
  }

  .urgent-task-deadline p,
  .urgent-task-info p,
  .task-todo-info p,
  .task-in-board-info p,
  .task-other-info p,
  .task-done-info p {
    font-size: 18px;
  }

  .urgent-task-deadline span {
    font-size: 23px;
  }

  .urgent-task-info span,
  .task-todo-info span,
  .task-in-board-info span,
  .task-other-info span,
  .task-done-info span {
    font-size: 36px;
  }

  .urgent-task-icon,
  .task-todo-icon,
  .task-in-board-icon,
  .task-other-icon,
  .task-done-icon {
    width: 90px;
  }

  .urgent-task-icon .img,
  .task-todo-icon .img,
  .task-in-board-icon .img,
  .task-other-icon .img,
  .task-done-icon .img {
    width: 42px;
    height: 42px;
  }
}

@media screen and (max-width: 600px) {
  .summary {
    padding: 24px;
    width: calc(100% - 48px);
  }

  .urgent-task-deadline,
  .urgent-task-info {
    padding: 0;
  }
}

@media screen and (max-width: 550px) {
  .summary-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .urgent-task,
  .task-todo {
    width: calc(100% + 4px);
    padding: 10px 20px;
  }

  .urgent-task span,
  .task-todo span,
  .task-in-board span,
  .task-other span,
  .task-done span {
    font-size: 28px;
  }

  .urgent-task p,
  .task-todo p,
  .task-in-board p,
  .task-other p,
  .task-done p {
    font-size: 16px;
  }

  .task-in-board,
  .task-other,
  .task-done {
    padding: 10px 20px;
  }

  .summary-content-container-upper {
    width: calc(100% - 48px);
    gap: 16px;
    padding-bottom: 16px;
  }

  .summary-content-container-lower {
    width: 100%;
    gap: 16px;
  }

  .urgent-task:hover .urgent-task-container,
  .task-todo:hover .task-todo-container,
  .task-other:hover .task-other-container,
  .task-done:hover .task-done-container,
  .task-in-board:hover .task-in-board-container {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 400px) {
  .summary {
    padding: 12px;
    width: calc(100% - 24px);
  }

  .urgent-task,
  .task-todo {
    width: calc(100% + 12px);
  }

  .urgent-task,
  .task-todo,
  .task-in-board,
  .task-other,
  .task-done {
    padding: 10px 15px;
  }

  .urgent-task-deadline span {
    font-size: 21px;
  }
}
