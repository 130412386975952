@import "./../../../styles/colors.css";

/*** Add task ***/

.add-task {
  padding: 48px;
  width: calc(100% - 96px);
}

.add-task-content {
  display: flex;
  min-height: 460px;
  min-width: 830px;
  max-width: 1400px;
  width: 100%;
}

.add-task-title p,
.add-task-description p,
.add-task-assigned p,
.add-task-date p,
.add-task-priority p,
.add-task-category p {
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 6px;
  margin: 0;
}

input,
textarea,
select {
  font-size: 20px;
  font-weight: 400;
  border-radius: 10px;
  border: 1px solid var(--light-gray);
  padding: 12px 21px;
  width: calc(100% - 42px);
  color: var(--black);
  background-color: var(--white);
  resize: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*** Left side ***/

.add-task-left {
  width: 45%;
}

/*** Middle side ***/

.add-task-middle {
  display: flex;
  justify-content: center;
  width: 10%;
  height: auto;
}

.line {
  width: 1px;
  background-color: var(--light-gray);
}

/*** Right side ***/

.add-task-right {
  width: 45%;
}

/* Priority */
.add-task-priority-btns {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 36px;
}
.add-task-priority-btn-text {
  display: flex;
  justify-content: center;
  font-size: 19px;
  font-weight: 400;
  gap: 8px;
}
.add-task-priority-btn {
  background-color: var(--white);
  font-size: 20px;
  font-weight: 400;
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--light-gray);
  padding: 10px 16px;
  cursor: pointer;
}
.add-task-priority-btn-active {
  cursor: unset;
  color: var(--white);
  box-shadow: none !important;
}
.add-task-priority-btn-active img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%)
    hue-rotate(346deg) brightness(99%) contrast(103%);
}
.add-task-priority-btn:hover {
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.3);
}

/* Category */
.add-task-category {
  position: relative;
}
.add-task-category select {
  width: 100%;
}
.add-task-category img {
  position: absolute;
  top: 53px;
  right: 20px;
  width: 14px;
  height: 7px;
}

/* Footer */
.add-task-footer {
  display: flex;
  justify-content: right;
  width: 100%;
  margin-top: 36px;
  gap: 32px;
}

/* Error message */

.red-dot {
  color: var(--light-red);
}

.error-msg {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 6px;
}

.error-msg p {
  font-size: 12px;
  font-weight: 400;
  color: var(--red);
}

/*------------- RESPONSIVE -------------*/

@media screen and (max-width: 950px) {
  .add-task-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
  }

  .add-task-left,
  .add-task-right {
    width: 100%;
  }

  .line {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .add-task {
    padding: 24px;
    width: calc(100% - 48px);
  }
}

@media screen and (max-width: 450px) {
  .add-task-priority-btn-text span {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .add-task {
    padding: 12px;
    width: calc(100% - 24px);
  }
}
