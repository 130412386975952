@import "./../../../../styles/colors.css";

.legal-notice {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  padding: 24px;
}

.legal-notice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.legal-notice h2 {
  font-size: 61px;
  font-weight: 700;
  width: 85%;
  margin: 0;
}

.legal-notice h3 {
  font-size: 27px;
  font-weight: 700;
  padding: 48px 0 24px 0;
  margin: 0;
}

.legal-notice h4 {
  font-size: 20px;
  font-weight: 700;
  padding: 24px 0;
  margin: 0;
}

.legal-notice p {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.03em;
  padding-bottom: 10px;
}

.legal-notice span {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.03em;
}

.legal-notice a {
  color: var(--light-blue);
  text-decoration: none;
}
.legal-notice a:hover {
  text-decoration: underline;
}

/* Media queries */

@media screen and (max-width: 500px) {
  .legal-notice h2 {
    font-size: 47px;
  }

  .legal-notice h3 {
    font-size: 27px;
  }
}
