@import "./../../../../styles/colors.css";

.add-contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 480px;
}
.add-contact-form input {
  background-color: var(--white);
  border: 1px solid var(--gray);
  border-radius: 10px;
  font-size: 20px;
  font-weight: 400;
  padding: 12px;
  margin: 0 12px;
  width: 90%;
}
.add-contact-form input:hover {
  border-color: var(--light-blue);
}
.add-contact-form input:focus {
  border-color: var(--light-blue);
  outline: none;
}

.add-contact-form-error-msg {
  min-height: 18px;
  padding: 6px;
  margin-left: 12px;
}
.add-contact-form-error-msg p {
  color: var(--red);
  font-size: 12px;
  font-weight: 400;
}

.add-contact-form-name {
  display: flex;
}

.add-contact-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .add-contact-form-name {
    flex-direction: column;
    width: 60%;
  }

  .add-contact-form {
    align-items: start;
  }
}

@media screen and (max-width: 600px) {
  .add-contact-form-name {
    width: 100%;
  }

  .add-contact-form {
    width: 340px;
    min-width: 340px;
  }
  .add-contact-form input {
    font-size: 18px;
    margin: 0 0 0 4px;
  }
}

@media screen and (max-width: 400px) {
  .add-contact-form {
    width: 250px;
    min-width: 250px;
  }
  .add-contact-form input {
    font-size: 16px;
    margin: 0 0 0 1px;
  }
}
