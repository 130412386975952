@import "./../../../../styles/colors.css";

.header {
  height: 96px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--light-blue);
}

.header-logo {
  width: 46px;
  height: 56px;
  margin-left: 24px;
}

.header-language {
  position: absolute;
  top: 12px;
  right: 12px;
  filter: brightness(0) saturate(100%) invert(0%) sepia(99%) saturate(7429%)
    hue-rotate(319deg) brightness(83%) contrast(102%);
}
.header-language:hover {
  filter: brightness(0) saturate(100%) invert(46%) sepia(51%) saturate(2924%)
    hue-rotate(202deg) brightness(102%) contrast(101%);
}

.header-container {
  display: flex;
  align-items: center;
  margin-right: 24px;
  gap: 32px;
  width: fit-content;
}

.header-title {
  font-size: 19px;
  color: var(--white);
}

.header-member-circle {
  cursor: pointer;
}

/* Auth header */
.header-auth {
  background-color: var(--white);
}

.header-logo-auth {
  position: fixed;
  left: 57px;
  top: 60px;
  height: 100px;
  width: 122px;
}

.header-register {
  position: fixed;
  top: 37px;
  right: 80px;
  display: inline-flex;
  align-items: center;
}
.header-register-question {
  display: flex;
  align-items: center;
  padding-right: 32px;
  font-size: 16px;
  font-weight: 400;
}

/* Media queries */
@media screen and (max-width: 1000px) {
  .header-logo-auth {
    left: 38px;
    top: 37px;
    height: 64px;
    width: 78px;
  }
}

@media screen and (max-width: 700px) {
  .header-title {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .header-register {
    top: unset;
    right: unset;
    left: 0;
    bottom: 79px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .header-register {
    bottom: 99px;
  }
}

@media (max-height: 680px) {
  .header-logo-auth {
    display: none;
  }

  .header-register {
    bottom: 79px;
  }
}
