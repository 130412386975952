@import "./../../../../styles/colors.css";

.help {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  padding: 24px;
}

.help a {
  text-decoration: none;
  color: var(--gray);
}

.help a:hover {
  color: var(--light-blue);
}

.blue-color {
  color: var(--light-blue);
}

.help h2 {
  font-size: 61px;
  font-weight: 700;
  margin: 0;
}

.help h3 {
  font-size: 27px;
  font-weight: 700;
  margin: 24px 0;
}

.help h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.help p {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.03em;
  padding-bottom: 10px;
}

.help-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.content-table {
  margin-top: 24px;
}

.content-table td:first-child {
  display: flex;
  font-size: 32px;
  font-weight: 700;
  margin-right: 35px;
}

.content-table p {
  margin: 16px 0;
}

.color {
  color: var(--light-blue);
}

/*------------- RESPONSIVE -------------*/
@media screen and (max-width: 500px) {
  .help h2 {
    font-size: 47px;
  }

  .help h3 {
    font-size: 27px;
  }

  .content-table td:first-child {
    margin-right: 12px;
  }
}
